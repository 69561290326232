<div id="contenedor">
    <div id="banner-superior">
        <div class="row">
            <div class="col-12 col-md-10 offset-md-1">Aviso de Privacidad</div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-10 offset-md-1 pt-5 pb-5 texto">
            <h3>Aviso de Privacidad integral de uso del sitio web</h3>
            Sistemas Dermatológicos Internacionales S.A. de C.V., con domicilio en Calle 14 #318, Zona Centro, C.P. 22800, Ensenada, Baja California, México, es el responsable del tratamiento de los datos personales que nos proporcione, los cuales serán protegidos conforme a lo dispuesto por Ley Federal de Protección de Datos Personales en Posesión de Particulares y su Ley Reglamentaria, y demás normatividad que resulte aplicable.<br><br>
            <b>Finalidades del tratamiento</b><br><br>
            Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades:<br><br>
            <ul>
                <li>Darlo de alta en nuestro sistema.</li>
                <li>Notificaciones vía correo electrónico para los distintos procesos de la tienda en línea.</li>
                <li>Cálculo de los impuestos aplicables a los productos en catálogo.</li>
                <li>Gestionar la entrega de mercancías al domicilio solicitado.</li>
                <li>Elaborar, gestionar y enviar facturas fiscales vía correo electrónico.</li>
                <li>Contactarle para dar seguimiento al producto adquirido.</li>
                <li>Realizar consultas, investigaciones y revisiones en relación a quejas o reclamaciones y
                elaborar informes estadísticos.</li>
                <li>Envío de información en la sección de contacto.</li>
            </ul>
            De manera adicional, utilizaremos su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:<br><br>
            <ul>
            <li>Informar sobre nuevos productos relacionados con productos adquiridos anteriormente.</li>
            <li>Consultar sobre la calidad de nuestro servicio y comprender las necesidades específicas de
            usted, y así poder brindarle un mejor servicio.</li>
            </ul>
            En caso de que no desee que sus datos personales sean tratados para estos fines adicionales, esta plataforma le permitirá indicarlo o usted puede manifestarlo así al correo electrónico atencion@siderica.com.<br><br>
            <b>Datos personales recabados</b><br><br>
            Para las finalidades antes señaladas se solicitarán los siguientes datos personales:<br><br>
            <ul>
            <li>Nombre completo.</li>
            <li>Correo electrónico.</li>
            <li>Teléfono.</li>
            <li>Empresa.</li>
            <li>Profesión.</li>
            <li>Estado.</li>
            <li>Ciudad.</li>
            </ul>
            Se informa que no se recaban datos personales sensibles.<br><br>
            <b>Transferencia de datos personales.</b><br><br>
            Únicamente serán transferidos sus datos personales a terceros nacionales o extranjeros, que estén relacionados jurídica o comercialmente con “Sistemas Dermatológicos Internacionales S.A. de C.V.” para cumplir con las finalidades descritas en el Aviso de Privacidad y en sus actualizaciones puestas a su disposición, por lo que “Sistemas Dermatológicos Internacionales S.A. de C.V.” , podrá transferir sus datos personales en los casos previstos y autorizados por la ley conforme a lo siguiente:<br><br>
            (i) Al Servicio de Administración Tributaria, en virtud de los procesos de emisión y envío de facturas electrónicas por Internet; (ii) A las personas morales o cualquier Entidad que formen parte del mismo grupo económico de control al que pertenece “Sistemas Dermatológicos Internacionales S.A. de C.V.” (iii) A compañías afiliadas o no afiliadas que asisten, apoyan o coadyuvan a “Sistemas Dermatológicos Internacionales S.A. de C.V.” ; (iv) A las diferentes autoridades para el desahogo de requerimientos o contestación de éstos en algún tipo de legislación o normatividad; (v) A su Banco en caso de realizar el cobro de nuestros servicios con tarjeta de crédito o débito; (vi) A Contadores y/o Abogados para el manejo, control y registro conforme a la normativa aplicable de los movimientos u operaciones económicos que hace la empresa.<br><br>
            En caso de que “Sistemas Dermatológicos Internacionales S.A. de C.V.” llegare a transferir sus Datos Personales a alguno de sus proveedores con el fin de llevar a cabo las Finalidades del Tratamiento establecidas en el presente Aviso de Privacidad, lo hará previa celebración de convenios de confidencialidad y, siempre y cuando (i) el proveedor o persona a quien se le transmitan acepte someter el tratamiento de los Datos Personales al presente Aviso de Privacidad, y (ii) no se trate de alguno de los supuestos establecidos en el artículo 37 de la Ley.<br><br>
            Si Usted no manifiesta su oposición para que sus Datos Personales sean transferidos a terceros, se entenderá que ha otorgado a “Sistemas Dermatológicos Internacionales S.A. de C.V.” su consentimiento para ello.<br><br>
            <b>Consentimiento para transferencia de datos personales</b><br><br>
            Le informamos que para la transferencia de datos personales descrita en el apartado anterior, no se requiere de su consentimiento.<br><br>
            <b>Derechos ARCO</b><br><br>
            Usted tiene derecho a conocer qué datos personales se tienen de usted, para qué se utilizan y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la ley (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.<br><br>
            Para el ejercicio de cualquiera de los derechos ARCO, usted podrá presentar solicitud por escrito al correo electrónico atencion@sidermica.com, la que deberá contener:<br><br>
            <ul>
            <li>El nombre del titular y su domicilio o cualquier otro medio para recibir notificaciones;</li>
            <li>Los documentos que acrediten la identidad del titular, y en su caso, la personalidad e identidad de su representante;</li>
            <li>De ser posible, el área responsable que trata los datos personales;</li>
            <li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos ARCO, salvo que se trate del derecho de acceso;</li>
            <li>La descripción del derecho ARCO que se pretende ejercer, o bien, lo que solicita el titular, y</li>
            <li>Cualquier otro elemento o documento que facilite la localización de los datos personales, en
            su caso.</li>
            </ul>
            En caso de solicitar la rectificación, adicionalmente deberá indicar las modificaciones a realizarse y aportar la documentación oficial necesaria que sustente su petición. En el derecho de cancelación debe expresar las causas que motivan la eliminación. Y en el derecho de oposición debe señalar los motivos que justifican se finalice el tratamiento de los datos personales y el daño o perjuicio que le causaría, o bien, si la oposición es parcial, debe indicar las finalidades específicas con las que se no está de acuerdo, siempre que no sea un requisito obligatorio.<br><br>
            La Unidad de Transparencia responderá en el domicilio o medio que el titular de los datos personales designe en su solicitud, en un plazo de 15 días hábiles, que puede ser ampliado por 10 días hábiles más previa notificación. La respuesta indicará si la solicitud de acceso, rectificación, cancelación u oposición es procedente y, en su caso, hará efectivo dentro de los 15 días hábiles siguientes a la fecha en que comunique la respuesta.<br><br>
            <b>Datos de la Unidad de Transparencia</b><br><br>
            Responsable: Leonela Contreras<br>
            Domicilio: Calle 14 #318, Zona Centro, C.P. 22800, Ensenada, Baja California, México.<br>
            Teléfono: +52 (646) 120 58 55<br>
            Correo electrónico institucional: atencion@sidermica.com<br><br>
            <b>Cambios al Aviso de Privacidad</b><br><br>
            El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de Privacidad; de cambios en nuestro modelo de negocio o por otras causas.<br><br>
            Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso de Privacidad, por lo que le recomendamos ingresar periódicamente a nuestro portal: www.sidermica.com y para cualquier duda envíe un correo al Responsable del tratamiento de sus datos personales al correo: atencion@sidermica.com.<br><br><br>
            Ultima actualización: 21 de Enero de 2021.
        </div>
    </div>
</div>