<div id="contenedor">
    <div id="banner-superior">
        <!-- <img src="/assets/img/Health_LP-874922006.jpg" class="d-none d-md-inline"> -->
        <!-- <div id="texto"> -->
        <div class="container">
            <div class="row">
                <div id="titulo" class="col-12 text-center">SALUD Y BIENESTAR</div>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-md-8 offset-md-2 text-center">
                    <p>INNOVATIVE SKINCARE se esfuerza por mejorar el bienestar físico y emocional de las personas en su totalidad. Empleamos lo más altos estándares éticos y de negocios a medida que avanzamos en la ciencia de la salud de la piel - una cara a la vez.</p>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </div>
    <div id="nuestra-filosofia" class="container pt-5 pb-3 pb-md-5">
        <div class="row">
            <div class="col-12 col-md-6 mt-0 mt-md-5 order-2 order-md-1 text-center text-md-left margen-negativo">
                <span id="titulo">Productos de Calidad</span>
                <p class="mb-md-0 mb-5">
                    Nuestros limpiadores, tratamientos, hidratantes y protectores solares de calidad superior se componen de los ingredientes más innovadores del mercado.
                </p>
            </div>
            <div class="col-12 col-md-6 text-center order-1 order-md-2">
                <img src="/assets/img/principios_rectores_productos_calidad.png" class="w-75">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <span id="esforzamos">Nos esforzamos continuamente para hacer mejoras en la salud y apariencia de la piel.</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                Utilizando un enfoque global de investigación y desarrollo, nuestra línea de productos iS CLINICAL combina la medicina botánica tradicional con la experiencia científica avanzada en formulación, haciendo que los resultados clínicos sean accesibles a un público más amplio que nunca.
            </div>
        </div>
    </div>
    <div id="etica" class="container pt-5 pb-5">
        <div class="row align-items-center">
            <div class="col-12 col-md-6" style="position: relative;">
                <img src="/assets/img/compass_3037.jpg" id="img-compass">
            </div>
            <div class="col-12 col-md-6 mt-5 mt-md-0 text-center">
                <div class="row mb-3">
                    <div class="col"><hr></div>
                    <div class="col-auto">
                        <img src="/assets/img/Layer_47.png" width="50">
                    </div>
                    <div class="col"><hr></div>
                </div>
                <span id="titulo">Los Más Altos Estándares Éticos</span>
                <p>
                    Nos dedicamos a desarrollar productos para el cuidado de la piel clínicamente validados que mejoran el bienestar físico y emocional de las personas en todo el mundo. Para asegurar éticamente el bienestar de nuestros clientes, damos la mayor importancia a las pruebas clínicas independientes para el máximo nivel de rendimiento y seguridad.
                </p>
                <div class="row mt-5">
                    <div class="col"><hr></div>
                    <div class="col-auto">
                        <img src="/assets/img/Layer_48.png" width="50">
                    </div>
                    <div class="col"><hr></div>
                </div>
            </div>
        </div>
    </div>
    <div id="banner-inferior">
        <img src="/assets/img/Layer_24.jpg">
    </div>
    <div id="filantropia" class="container">
        <div class="row">
            <div class="col-12 col-md-3 offset-md-2 pt-md-1 pb-md-0 pr-md-0 d-none d-md-inline">
                <img src="/assets/img/cancer_care_ladies.png" class="w-100">
            </div>
            <div id="iscancercare" class="col-12 col-md-7 pt-5 pb-5 pt-md-5 pb-md-5 pl-5 pl-md-5 pr-5 pr-md-5 mt-md-n5">
                <span id="titulo">iS CANCER CARE & FILANTROPÍA</span>
                <p class="pt-0 mt-0">
                    El programa iS Cancer Care se inició en el instituto de Cáncer de Washington D.C. a principios de 2003 y ha tenido un gran éxito. Desde entonces, el programa se ha ampliado a otras instituciones respetadas, como el Cedars-Sinai Brandman Breast Center, el Taussig Cancer Institute de la Clínica Cleveland y el Revlon Breast Center de la UCLA, así como varias instituciones internacionales.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 pr-0 pl-0 pl-md-3">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/SaQ16O6Wj20" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-12 col-md-6 pl-0 pr-0 pr-md-3">
                <img src="/assets/img/phil-image-02.jpg" class="w-100">
                <p class="pl-5 pr-5 pt-4">
                    Debido a este éxito, INNOVATIVE SKINCARE lanzó el programa iS Cancer Care Spa Day en 2013 para permitir a nuestros socios extender este alcance caritativo a sus propias comunidades a través de eventos de Día de Spa fácilmente gestionables.
                </p>
            </div>
        </div>
    </div>
</div>