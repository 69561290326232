<div id="contenedor">
    <div id="banner-superior">
        <img src="/assets/img/banner-principal.png">
        <div id="boton"><a href="https://bit.ly/atencionalcliente_isclinicalmx" target="_blank">CONTÁCTANOS</a></div>
    </div>
    <div id="seccion1" class="container mt-5 pt-5 pb-5">
        <div class="row mb-2">
            <div id="titulo" class="col-12 text-center">FACIALES CLÍNICOS EN CASA</div>
        </div>
        <div class="row mb-5">
            <div id="descripcion" class="col-12 col-md-10 offset-md-1 text-center">
                Para complementar nuestros productos de cuidado del hogar, iS CLINICAL ofrece una gama de lujosos y poderosos protocolos faciales profesionales dramáticamente efectivos para tratar visualmente todos los tipos de piel y preocupaciones.
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md text-center text-white">
                <img src="/assets/img/warmUpCoolDown_v3.jpg" class="imagenes-como-usar"><br>
                <span class="titulos-como-usar">FACIAL WARM UP, COOL DOWN</span>
                <p class="texto-como-usar">Disfruta de un estímulo para el cutis con este facial de aplicación en casa que ayuda a obtener una piel brillante mientras fomenta la salud general de la piel. WARMING HONEY CLEANSER limpia y proporciona una exfoliación suave, permitiendo que HYDRA-INTENSIVE COOLING MASQUE revitalice y refresque con una hidratación profunda.</p>
                <a href="/assets/img/FACIAL-WARM-UP-COOL-DOWN.jpg" target="_blank" class="botones-como-usar">APLICACIÓN</a>
            </div>
            <div class="col-12 col-md offset-md-1 mt-5 mt-md-0 text-center text-white">
                <img src="/assets/img/TAEM_HICM_adj_v3.jpg" class="imagenes-como-usar"><br>
                <span class="titulos-como-usar">FACIAL SMOOTH & SOOTHE</span>
                <p class="texto-como-usar">Un facial para aplicar en casa en dos sencillos pasos. TRI-ACTIVE EXFOLIATING MASQUE ayuda de forma suave pero poderosa a revitalizar la piel con una combinación óptima de exfoliación física y química amistosa con el medio ambiente. La piel reseca o deshidratada vuelve a la vida con la aplicación de HYDRA-INTENSIVE COOLING MASQUE que brinda una explosión de frescura.</p>
                <a href="/assets/img/FACIAL-SMOOTH-&-SOOTHE.jpg" target="_blank" class="botones-como-usar">APLICACIÓN</a>
            </div>
        </div>
    </div>
    <div class="container mt-5 pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <owl-carousel [options]="customOptions">
                    <div class="item">
                        <img src="/assets/img/productos/cleansing_complex.png">
                        <span>CLEANSING COMPLEX</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/cream_cleanser.png">
                        <span>CREAM CLEANSER</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/lip_duo.png">
                        <span>LIP DUO</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/active_serum.png">
                        <span>ACTIVE SERUM</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/proheal_serum.png">
                        <span>PRO-HEAL SERUM ADVANCE+</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/youth_eye.png">
                        <span>YOUTH EYE COMPLEX</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/super_serum.png">
                        <span>SUPER SERUM ADVANCE+</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/genexc_serum.png">
                        <span>GENEXC SERUM</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/youth_intensive.png">
                        <span>YOUTH INTENSIVE CREME</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/sheald_recovery.png">
                        <span>SHEALD RECOVERY BALM</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/youth_complex.png">
                        <span>YOUTH COMPLEX</span>
                    </div>
                </owl-carousel>
            </div>
        </div>
    </div>
    <div class="container mt-5 pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <owl-carousel [options]="customOptions">
                    <div class="item">
                        <img src="/assets/img/productos/neckperfect_complex.png">
                        <span>NECKPERFECT COMPLEX</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/copper_firming.png">
                        <span>COPPER FIRMING MIST</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/c_eye_serum.png">
                        <span>C EYE SERUM ADVANCE+</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/polyvitamin_serum.png">
                        <span>POLY-VITAMIN SERUM</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/hydra_intensive.png">
                        <span>HYDRA-INTENSIVE COOLING MASQUE</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/hydracool_serum.png">
                        <span>HYDRA-COOL SERUM</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/reparative_moisture.png">
                        <span>REPARATIVE MOISTURE EMULSION</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/extreme_protect.png">
                        <span>EXTREME PROTECT SPF 30</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/eclipse_spf.png">
                        <span>ECLIPSE SPF 50+</span>
                    </div>
                    <div class="item">
                        <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                        <span>ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                    </div>
                </owl-carousel>
            </div>
        </div>
    </div>
</div>