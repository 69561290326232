<div id="contenedor">
    <div id="banner-superior">
        <div id="video">
            <video autoplay loop>
                <source src="/assets/videos/chromosome_loop_3.mp4" type="video/mp4">
                <source src="/assets/videos/chromosome_loop_3.ogv" type="video/ogg">
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="container">
            <div class="row">
                <div id="subtitulo" class="col-12 col-md-5">Your Skin...</div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8"><h1 id="titulo">Our Science...</h1></div>
            </div>
            <div class="row">
                <div id="cursiva" class="col-12 col-md-7 text-right">Pure Chemistry.</div>
            </div>
            <div class="row mt-5 mt-md-4">
                <div class="col-12 col-md-5">
                    <p>INNOVATIVE SKINCARE está dedicada a la creación de productos efectivos y clínicamente evaluados para el cuidado de la piel, capaces de brindar mejoras sobre la piel.</p>
                </div>
            </div>
        </div>
    </div>
    <div id="conociendo-tu-piel">
        <div class="container pt-5 pb-5">
            <div class="row mt-5">
                <div id="titulo" class="col-12 col-md-5 offset-md-8">
                    Conociendo tu piel
                </div>
                <div class="col-12 col-md-5 offset-md-8 mt-3 pb-md-0">
                    <p>La piel es un órgano complejo, compuesto de dos capas principales: la epidermis y la dermis, además de ser el órgano más grande del cuerpo, la piel es también uno de los más importantes, protegiendo el resto del cuerpo del ambiente exterior.</p>
                </div>
                <div id="imgepidermis" class="col-12 col-md-8 offset-md-3 mt-0 pt-5 pt-md-0 pb-5 pb-md-0">
                    <img src="/assets/img/epidermis-01-24bit.png" class="w-100">
                </div>
                <div class="col-12 col-md-5 offset-md-8 mt-5">
                    <p>La capa exterior, la epidermis, está compuesta por cuatro capas, que son responsables de mantener el agua dentro, mantener la infección fuera y ayudar a regular la temperatura del cuerpo - entre otras funciones vitales. La capa interna, la dermis, está compuesta principalmente de tejido conectivo que proporciona la fuerza y la elasticidad que tu piel necesita para resistir los factores de estrés. Ambas capas son vitales para entender y tratar casi todas las condiciones y preocupaciones de la piel.</p>
                </div>
            </div>
        </div>
    </div>
    <div id="capas-piel" class="container">
        <div class="row pt-5 pb-5">
            <div id="titulo" class="col-12 text-center">Las Capas de la Piel Humana</div>
            <div class="col-12 mt-5 mb-5 text-center">
                <img src="/assets/img/Capas_de_la_piel.png" class="w-100">
            </div>
        </div>
    </div>
    <div id="factores" class="container pb-5 mb-5">
        <div class="row">
            <div style="position: relative;" class="col-10 offset-1 col-md-5 offset-md-2 text-right text-md-left order-2 order-md-1">
                <img id="foto-mujer-agua" src="/assets/img/img-05.jpg">
            </div>
            <div class="col-12 col-md-5 order-1 order-md-2 mb-5 mb-md-0">
                <span id="titulo">Los Factores</span>
                <p>Una multitud de factores contribuyen al aspecto exterior de tu piel: los agresores ambientales como el sol, la contaminación y el clima; los agresores internos, como la dieta y la hidratación; y su propia bioquímica, como las hormonas y las predisposiciones genéticas. Tanto si busca mejorar el aspecto de la piel envejecida, las manchas, el tono desigual de la piel, el enrojecimiento u otras condiciones, el intrincado sistema de la piel requiere un enfoque multifacético para el tratamiento.</p>
            </div>
        </div>
        <div id="margen-negativo" class="row">
            <div id="fondo-factors" class="col-10 offset-1 col-md-12 pt-3 pb-3">
                <div class="row h-100">
                    <div id="subfondo-factors" class="col-12 col-md-7">
                        <img id="foto-dna" src="/assets/img/science-dna-image.jpg" class="mb-5 mb-md-0">
                        <div id="texto" class="mt-md-0 mt-5 pt-5 pt-md-0 pl-1 pl-md-5 pr-1 pr-md-5">
                            La piel humana es muy compleja y para crear soluciones para el cuidado de la piel que funcionen eficazmente, se requiere una formulación química precisa, amplios conocimientos de bioquímica dermatológica y los ingredientes de más alto grado disponibles.<br><br>
                            Estamos comprometidos a incorporar este nivel más alto de validación clínica en todos los productos.
                        </div>
                    </div>
                    <div class="col-12 col-md-5 p-0 p-md-3">
                        <img src="/assets/img/img-06.jpg" class="w-100">
                        <div id="caracteristicas">
                            <span>Clima</span>
                            <span>Contaminación Ambiental</span>
                            <span>Dieta</span>
                            <span>Hidratación</span>
                            <span>Hormonas</span>
                            <span>Predisposición Genética</span>
                        </div>
                        <img src="/assets/img/img-07.jpg" class="w-100">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="extremozyme">
        <div id="video">
            <video autoplay loop>
                <source src="/assets/videos/dunes_5917_4.mp4" type="video/mp4">
                <source src="/assets/videos/dunes_5917_4.ogv" type="video/ogg">
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-5">
                    <span class="titulo">Tecnología<br>Extremozyme®</span>
                    <p>Las Extremozimas son enzimas producidas por plantas que viven en ambientes extremos que ayudan a la supervicencia y la adaptabilidad. Estos sucesos de adaptación también pueden utilizarse para proteger la piel humana de los daños causados por los extremos ambientales.<br><br>
                    <span class="d-none d-md-inline">Nos basamos en los procesos de selección natural de organismos extremófilos especializados, siendo pioneros en el futuro del cuidado de la piel en cuanto a la protección definitiva de una piel sana.</span></p>
                </div>
                <div id="video2" class="col-12 col-md-7 pl-0 pr-0 pr-md-3">
                    <video autoplay loop>
                        <source src="/assets/videos/extremozymes_2VBR.mp4" type="video/mp4">
                        <source src="/assets/videos/extremozymes_2VBR.ogv" type="video/ogg">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div class="col-12 d-inline d-md-none mt-5 mb-5">
                    <p>Nos basamos en los procesos de selección natural de organismos extremófilos especializados, siendo pioneros en el futuro del cuidado de la piel en cuanto a la protección definitiva de una piel sana.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-5 pl-0 pr-0 text-center">
                    <img src="/assets/img/img-02.jpg" class="w-100">
                    <span class="titulo mt-5">Fuentes de Extremozyme</span>
                    <p>Fosas oceánicas profundas<br><br>
                    La tundra ártica frígida<br><br>
                    Climas desérticos áridos<br><br>
                    Selvas calientes y húmedas<br><br>
                    Aguas termales y géiseres</p>
                </div>
                <div class="col-12 col-md-5 pl-md-0 mt-5 mt-md-0">
                    <img src="/assets/img/img-03.jpg" class="w-100">
                </div>
            </div>
        </div>
    </div>
</div>