import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  	providedIn: 'root'
})
export class FuncionesGeneralesService {

	url:string = "https://isclinical.sidermica.com/webservice/1scl1n1c4l.php"

	constructor(private http: HttpClient) { }

	conexionServidor(datos):Promise<any>{

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/x-www-form-urlencoded;charset=utf-8'
			}),
			params: new HttpParams({
				fromString: datos
			}),
			responseType: 'json' as 'json'
		};

		return new Promise((respuesta) => {
			this.http.post(this.url, datos,httpOptions)
			.subscribe(
				(data) => {
					respuesta(data)
				},
				(error) => {
					respuesta({
						respuesta: "ERROR",
						titulo: "ERROR",
						mensaje: error
					})
				}
			)
		})
	}
}
