import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';

import { InicioComponent } from './inicio/inicio.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlModule } from 'ngx-owl-carousel';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ComunicadoCovidComponent } from './comunicado-covid/comunicado-covid.component';
import { ContactoComponent } from './contacto/contacto.component';
import { RegimenComponent } from './regimen/regimen.component';
import { FaqComponent } from './faq/faq.component';
import { NuestraCienciaComponent } from './nuestra-ciencia/nuestra-ciencia.component';
import { PreocupacionesPielComponent } from './preocupaciones-piel/preocupaciones-piel.component';
import { PrincipiosRectoresComponent } from './principios-rectores/principios-rectores.component';
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component';

@NgModule({
declarations: [
	AppComponent,
	InicioComponent,
	ComunicadoCovidComponent,
	ContactoComponent,
	RegimenComponent,
	FaqComponent,
	NuestraCienciaComponent,
	PreocupacionesPielComponent,
	PrincipiosRectoresComponent,
	AvisoPrivacidadComponent
],
imports: [
	BrowserModule,
	AppRoutingModule,
	BrowserAnimationsModule,
	CarouselModule,
	FormsModule,
	HttpClientModule,
	IvyCarouselModule,
	OwlModule
],
providers: [],
bootstrap: [AppComponent]
})
export class AppModule { }
