<div id="contenedor">
    <div id="banner-superior">
        <div class="row">
            <div class="col-12 col-md-10 offset-md-1">FAQ</div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-10 offset-md-1 pt-5 pb-5">
            <p>
                LA LISTA DE INGREDIENTES TIENE SUSTANCIAS QUE SUENAN COMO QUÍMICOS. ¿NO SON LOS QUÍMICOS DAÑINOS?<br>
                Cada sustancia en el mundo es una sustancia química y, como tal, puede ser meticulosamente descrita en el lenguaje científico con un nombre químico - incluso el agua puede ser conocida como monóxido de dihidrógeno o dióxido de hidrógeno, que son en realidad los términos químicos para el H2O. La línea de productos iS CLINICAL® es única en el sentido de que utiliza ingredientes de la más alta calidad y pureza. Nuestras materias primas tienen una base botánica y son de calidad farmacéutica, y no contienen rellenos, metales tóxicos ni impurezas. Cumplen los criterios de su C de A bioquímico (Certificado de Análisis) en nuestra planta de producción.<br><br>
                ¿SUS PRODUCTOS SON HIPOALERGÉNICOS?<br>
                Al igual que otros productos denominados "hipoalergénicos", los nuestros no contienen fragancias innecesarias, conservantes químicos u otros productos químicos. Además, utilizamos materias primas de calidad farmacéutica (esta es una distinción extremadamente importante), libres de impurezas y compuestos "polizones". Sin embargo, debido a que nuestros productos contienen muchos ingredientes botánicos y "activos", es posible que se desarrollen sensibilidades ocasionales a los productos - la única manera de evitar esto es crear productos inertes, que no harían nada beneficioso para la piel. Dicho esto, muy rara vez vemos reacciones alérgicas a nuestros productos y siempre tenemos cuidado de evitar los irritantes conocidos en nuestras formulaciones. Si le preocupan las posibles sensibilidades a nuestros productos, por favor consulte con un médico o profesional de la piel y pruebe el producto antes de aplicarlo en la cara.<br><br>
                ¿LOS INGREDIENTES UTILIZADOS EN LOS PRODUCTOS CLÍNICOS ESTÁN CERTIFICADOS COMO ORGÁNICOS? SI NO, ¿POR QUÉ?<br>
                Los productos no están certificados como orgánicos, aunque la empresa compra orgánicos siempre que es posible. No siempre es posible comprar todos los ingredientes con certificación orgánica completa, ya que esto implica certificaciones a muchos niveles en diferentes países y con diversos organismos. No siempre es posible en un sentido práctico. Además, los productos de iS CLINICAL están diseñados para contener ingredientes de base botánica, es decir, de plantas.<br><br>
                ¿ES ACONSEJABLE QUE UN CLIENTE CAMBIE DE REPENTE O GRADUALMENTE DE UNA LÍNEA DE PRODUCTOS DIFERENTE A LA SUYA?<br>
                Un cambio repentino está bien, basado en las necesidades de la condición de la piel o en los cambios estacionales. Sin embargo, si tiene un historial de sensibilidad, se recomienda una prueba de parcheo de nuestros productos. Recomendamos una consulta de cuidado de la piel de temporada y un plan de tratamiento con un profesional de la piel para satisfacer mejor las necesidades cambiantes de la piel y tal vez el estilo de vida de los clientes.<br><br>
                ¿PRUEBAN EN ANIMALES O UTILIZAN PRODUCTOS ANIMALES EN TU PRODUCTO?<br>
                No, nunca hacemos pruebas con animales y no usamos ingredientes de origen animal.<br><br>
                ¿PUEDO OBTENER MUESTRAS DE SUS PRODUCTOS?<br>
                No proporcionamos muestras directamente a los consumidores - sin embargo, puede obtener muestras de nuestros productos a través de uno de nuestros socios. ¡Haga clic aquí para encontrar un socio cerca de usted!
            </p>
        </div>
    </div>
</div>