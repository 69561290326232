import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-principios-rectores',
  templateUrl: './principios-rectores.component.html',
  styleUrls: ['./principios-rectores.component.css']
})
export class PrincipiosRectoresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
