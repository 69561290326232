import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

	mostrarMenu(){
		document.getElementById('menu-lateral').style.display = 'inline';
	}

	ocultarMenu(){
		document.getElementById('menu-lateral').style.display = 'none';
	}

}
