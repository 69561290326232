import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component'
import { RegimenComponent } from './regimen/regimen.component'
import { FaqComponent } from './faq/faq.component'
import { ContactoComponent } from './contacto/contacto.component'
import { ComunicadoCovidComponent } from './comunicado-covid/comunicado-covid.component'
import { NuestraCienciaComponent } from './nuestra-ciencia/nuestra-ciencia.component'
import { PreocupacionesPielComponent } from './preocupaciones-piel/preocupaciones-piel.component'
import { PrincipiosRectoresComponent } from './principios-rectores/principios-rectores.component'
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component'

const routes: Routes = [
	{path: '', redirectTo: 'inicio', pathMatch: 'full'},
	{path: 'inicio', component: InicioComponent},
	{path: 'comunicado-covid', component: ComunicadoCovidComponent},
	{path: 'regimen', component: RegimenComponent},
	{path: 'faq', component: FaqComponent},
	{path: 'contacto', component: ContactoComponent},
	{path: 'nuestra-ciencia', component: NuestraCienciaComponent},
	{path: 'preocupaciones-piel', component: PreocupacionesPielComponent},
	{path: 'principios-rectores', component: PrincipiosRectoresComponent},
	{path: 'aviso-privacidad', component: AvisoPrivacidadComponent}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
