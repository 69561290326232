import { Component, OnInit } from '@angular/core';
import { FuncionesGeneralesService } from '../funciones-generales.service';

@Component({
	selector: 'app-contacto',
	templateUrl: './contacto.component.html',
	styleUrls: ['./contacto.component.css']
})

export class ContactoComponent implements OnInit {

	txtNombre:string = ""
	txtCorreo:string = ""
	txtTelefono:string = ""
	txtComentario:string = ""

	constructor(private funcionesGenerales: FuncionesGeneralesService) { }

	ngOnInit(): void {
	}

	enviarFormulario(nombre,correo,telefono,comentario){
		if(nombre==""){
			alert("ATENCIÓN: Debes indicar un nombre.")
		}else if(correo==""){
			alert("ATENCIÓN: Debes indicar un correo electrónico.")
		}else if(telefono==""){
			alert("ATENCIÓN: Debes indicar un teléfono.")
		}else if(comentario==""){
			alert("ATENCIÓN: Debes indicar un comentario.")
		}else{
			this.funcionesGenerales.conexionServidor("action=correo&nombre=" + nombre + "&correo=" + correo + "&telefono=" + telefono + "&comentario=" + comentario)
			.then(respuesta => {
				if(respuesta.respuesta=="success"){
					alert("ATENCIÓN: El formulario se ha enviado correctamente.")
					this.txtNombre = ""
					this.txtCorreo = ""
					this.txtTelefono = ""
					this.txtComentario = ""
				}else if(respuesta.respuesta=="error"){
					alert(respuesta.mensaje)
				}else{
					alert(JSON.stringify(respuesta))
				}
			})
		}
	}

}
