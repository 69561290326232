<div id="contenedor">
    <div id="banner-superior">
        <div class="row align-items-center">
            <div class="col-10 offset-1 text-center text-md-left col-md-4 offset-md-1">RÉGIMEN</div>
            <div class="col-10 offset-1 col-md-6 offset-md-0">
                <img src="/assets/img/regimen_background.png" class="w-100">
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="cleanse">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-4 offset-md-0 text-center">
                    <img src="/assets/img/Cleanse_regimen.png" class="w-100">
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">CLEANSE</span>
                    <p>
                        El primer paso en el régimen iS CLINICAL:<br><br>
                        Limpieza<br><br>
                        Es vital para mantener una piel limpia, fresca y vibrante. Nuestros limpiadores son ideales para todos los tipos de piel, edades y géneros. Suaves pero eficaces agentes de renovación, equilibrados con potentes antioxidantes, eliminan el exceso de grasa, maquillaje y residuos sin causar irritación o resequedad.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosCleanse">
                        <li><input type="checkbox" class="filtros" data-filtro="manchas" (click)="filtrarProductos('Cleanse');"> Manchas/Brotes</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('Cleanse');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_opaca" (click)="filtrarProductos('Cleanse');"> Piel opaca</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('Cleanse');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="rubor" (click)="filtrarProductos('Cleanse');"> Rubor/Enrojecimiento</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('Cleanse');"> Arrugas en el área de los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_posterior" (click)="filtrarProductos('Cleanse');"> Piel posterior a procedimientos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="lineas_finas" (click)="filtrarProductos('Cleanse');"> Líneas finas y arrugas</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselCleanse">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/cleansing_complex.png">
                                <span>CLEANSING COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/cream_cleanser.png">
                                <span>CREAM CLEANSER</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselCleanseBK" style="display: none;">
                        <div class="item" data-filtro="manchas piel_seca piel_opaca tonos_desiguales rubor piel_posterior lineas_finas" style="text-align: center;">
                            <img src="/assets/img/productos/cleansing_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CLEANSING COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor arrugas piel_posterior lineas_finas">
                            <img src="/assets/img/productos/cream_cleanser.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CREAM CLEANSER</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="treat">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">TREAT</span>
                    <p>
                        El segundo paso en el régimen iS CLINICAL: Tratamiento<br><br>
                        Permite centrarse en preocupaciones y condiciones específicas de la piel con potentes ingredientes botánicos activos. Clínicamente poderosos, pero no irritantes, nuestros productos de la categoría TREAT han demostrado que mejoran la apariencia de la piel envejecida, las manchas, el tono desigual de la piel, el enrojecimiento, la falta de luminosidad, los poros dilatados y más.
                    </p>
                </div>
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <img src="/assets/img/regimen_treat.png" class="w-100">
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosTreat">
                        <li><input type="checkbox" class="filtros" data-filtro="manchas" (click)="filtrarProductos('Treat');"> Manchas/Brotes</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('Treat');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_opaca" (click)="filtrarProductos('Treat');"> Piel opaca</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('Treat');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="rubor" (click)="filtrarProductos('Treat');"> Rubor/Enrojecimiento</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('Treat');"> Arrugas en el área de los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_posterior" (click)="filtrarProductos('Treat');"> Piel posterior a procedimientos</li>
                        <!-- <li><input type="checkbox" class="filtros" data-filtro="lineas_finas" (click)="filtrarProductos('Treat');"> Líneas finas y arrugas</li> -->
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselTreat">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/lip_duo.png">
                                <span>LIP DUO</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/active_serum.png">
                                <span>ACTIVE SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/proheal_serum.png">
                                <span>PRO-HEAL SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/youth_eye.png">
                                <span>YOUTH EYE COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/super_serum.png">
                                <span>SUPER SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/genexc_serum.png">
                                <span>GENEXC SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/youth_intensive.png">
                                <span>YOUTH INTENSIVE CREME</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/sheald_recovery.png">
                                <span>SHEALD RECOVERY BALM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/youth_complex.png">
                                <span>YOUTH COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/neckperfect_complex.png">
                                <span>NECKPERFECT COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/copper_firming.png">
                                <span>COPPER FIRMING MIST</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/c_eye_serum.png">
                                <span>C EYE SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/polyvitamin_serum.png">
                                <span>POLY-VITAMIN SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/hydra_intensive.png">
                                <span>HYDRA-INTENSIVE COOLING MASQUE</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselTreatBK" style="display: none;">
                        <div class="item" data-filtro="piel_seca piel_opaca">
                            <img src="/assets/img/productos/lip_duo.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">LIP DUO</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_opaca tonos_desiguales">
                            <img src="/assets/img/productos/active_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ACTIVE SERUM</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_seca rubor piel_posterior">
                            <img src="/assets/img/productos/proheal_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">PRO-HEAL SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="piel_seca arrugas">
                            <img src="/assets/img/productos/youth_eye.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">YOUTH EYE COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="manchas tonos_desiguales">
                            <img src="/assets/img/productos/super_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SUPER SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="tonos_desiguales">
                            <img src="/assets/img/productos/genexc_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">GENEXC SERUM</span>
                        </div>
                        <div class="item" data-filtro="piel_seca piel_posterior">
                            <img src="/assets/img/productos/youth_intensive.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">YOUTH INTENSIVE CREME</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor piel_posterior">
                            <img src="/assets/img/productos/sheald_recovery.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SHEALD RECOVERY BALM</span>
                        </div>
                        <div class="item" data-filtro="piel_seca piel_opaca">
                            <img src="/assets/img/productos/youth_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">YOUTH COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="piel_seca piel_opaca">
                            <img src="/assets/img/productos/neckperfect_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">NECKPERFECT COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="piel_seca arrugas">
                            <img src="/assets/img/productos/copper_firming.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">COPPER FIRMING MIST</span>
                        </div>
                        <div class="item" data-filtro="tonos_desiguales arrugas">
                            <img src="/assets/img/productos/c_eye_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">C EYE SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="piel_seca">
                            <img src="/assets/img/productos/polyvitamin_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">POLY-VITAMIN SERUM</span>
                        </div>
                        <div class="item" data-filtro="piel_seca piel_opaca arrugas">
                            <img src="/assets/img/productos/hydra_intensive.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">HYDRA-INTENSIVE COOLING MASQUE</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="hydrate">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <img src="/assets/img/regimen_hydrate.png" class="w-100">
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">HYDRATE</span>
                    <p>
                        El tercer paso en el régimen iS CLINICAL: Hidratación<br><br>
                        Repone y equilibra la hidratación de la piel para mantener la sensación de una piel más suave, firme y saludable. Nuestra categoría de productos HYDRATE mejora drásticamente la hidratación y la textura de la piel, sin obstruir los poros ni causar una sensación de pesadez. Los productos iS CLINICAL HYDRATE son ideales para todo tipo de piel, edad y género, y están formulados para aplicarse con nuestros productos TREAT y PROTECT.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosHydrate">
                        <li><input type="checkbox" class="filtros" data-filtro="manchas" (click)="filtrarProductos('Hydrate');"> Manchas/Brotes</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('Hydrate');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_opaca" (click)="filtrarProductos('Hydrate');"> Piel opaca</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('Hydrate');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="rubor" (click)="filtrarProductos('Hydrate');"> Rubor/Enrojecimiento</li>
                        <!-- <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('Hydrate');"> Arrugas en el área de los ojos</li> -->
                        <li><input type="checkbox" class="filtros" data-filtro="piel_posterior" (click)="filtrarProductos('Hydrate');"> Piel posterior a procedimientos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="lineas_finas" (click)="filtrarProductos('Hydrate');"> Líneas finas y arrugas</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselHydrate">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/lip_duo.png">
                                <span>LIP DUO</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/hydracool_serum.png">
                                <span>HYDRA-COOL SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/reparative_moisture.png">
                                <span>REPARATIVE MOISTURE EMULSION</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselHydrateBK" style="display:none;">
                        <div class="item" data-filtro="piel_seca piel_opaca lineas_finas">
                            <img src="/assets/img/productos/lip_duo.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">LIP DUO</span>
                        </div>
                        <div class="item" data-filtro="piel_opaca tonos_desiguales rubor piel_posterior lineas_finas">
                            <img src="/assets/img/productos/hydracool_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">HYDRA-COOL SERUM</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_seca piel_opaca tonos_desiguales rubor piel_posterior lineas_finas">
                            <img src="/assets/img/productos/reparative_moisture.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">REPARATIVE MOISTURE EMULSION</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="protect">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">PROTECT</span>
                    <p>
                        Vital para todos los tipos y condiciones de piel, el cuarto paso en el régimen iS CLINICAL es la protección de los rayos solares dañinos. Nuestros protectores solares UVA/UVB de amplio espectro están formulados no sólo para ayudar a proteger contra los efectos visibles de las quemaduras solares y la foto envejecimiento, sino también para dar a la piel una profunda hidratación.
                    </p>
                </div>
                <div class="col-12 col-md-4">
                    <img src="/assets/img/regimen_protect.png" class="w-100">
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosProtect">
                        <li><input type="checkbox" class="filtros" data-filtro="manchas" (click)="filtrarProductos('Protect');"> Manchas/Brotes</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('Protect');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_opaca" (click)="filtrarProductos('Protect');"> Piel opaca</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('Protect');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="rubor" (click)="filtrarProductos('Protect');"> Rubor/Enrojecimiento</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('Protect');"> Arrugas en el área de los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_posterior" (click)="filtrarProductos('Protect');"> Piel posterior a procedimientos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="lineas_finas" (click)="filtrarProductos('Protect');"> Líneas finas y arrugas</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselProtect">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/extreme_protect.png">
                                <span>EXTREME PROTECT SPF 30</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/eclipse_spf.png">
                                <span>ECLIPSE SPF 50+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                                <span>ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselProtectBK" style="display: none;">
                        <div class="item" data-filtro="piel_opaca rubor arrugas piel_posterior lineas_finas">
                            <img src="/assets/img/productos/extreme_protect.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">EXTREME PROTECT SPF 30</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_seca tonos_desiguales rubor arrugas piel_posterior lineas_finas">
                            <img src="/assets/img/productos/eclipse_spf.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ECLIPSE SPF 50+</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_seca tonos_desiguales rubor arrugas piel_posterior lineas_finas">
                            <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>