import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

	customOptions: any = {
		loop: true,
		margin: 10,
		autoplay:true,
		navText: ['&#8249', '&#8250;'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			940: {
				items: 4
			}
		},
		nav: true,
		dots: false
	}

  	constructor() { }

  	ngOnInit(): void {

  	}

}
