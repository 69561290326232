import { Component, OnInit, ViewChild } from '@angular/core';
import { $ } from 'protractor';
import * as jQuery from 'jquery';

declare var jQuery: any;

@Component({
	selector: 'app-regimen',
	templateUrl: './regimen.component.html',
	styleUrls: ['./regimen.component.css']
})
export class RegimenComponent implements OnInit {
	
  	customOptions = {
		loop: false,
		margin: 10,
		autoplay:false,
		navText: ['&#8249', '&#8250;'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 3
			}
		},
		nav: true,
		dots: false
	}

  	constructor() { }

	ngOnInit(): void {
	}

	filtrarProductos(carrusel){
		jQuery("#carrusel" + carrusel).html("");
		jQuery("#carrusel" + carrusel).html("<div class=\"owl-carousel\"></div>");
		if(jQuery(".filtros:checked","#filtros" + carrusel).length>0){
			var arrayFiltros = []
			jQuery(".filtros:checked","#filtros" + carrusel).each(function(i){
				arrayFiltros.push(jQuery(this).data("filtro"));
			});
			jQuery(".item","#carrusel" + carrusel + "BK").each(function(i){
				var copiar = false
				var filtrosElemento = jQuery(this).data("filtro").split(" ");
				for(var j=0;j<filtrosElemento.length;j++) {
					if(arrayFiltros.includes(filtrosElemento[j])){
						copiar = true
					}
				}
				if(copiar){
					jQuery(".owl-carousel","#carrusel" + carrusel).append("<div class=\"item\" style=\"text-align: center;\">" + jQuery(this).html() + "</div>");
				}
			});
		}else{
			jQuery(".item","#carrusel" + carrusel + "BK").each(function(i){
				jQuery(".owl-carousel","#carrusel" + carrusel).append("<div class=\"item\" style=\"text-align: center;\">" + jQuery(this).html() + "</div>");
			});
		}
		jQuery(".owl-carousel","#carrusel" + carrusel).owlCarousel(this.customOptions);
	}

}
