<div id="menu-superior">
	<div class="container-fluid">
		<div class="row">
			<div class="col-3 col-md-2"><a (click)="mostrarMenu();"><i class="fas fa-bars fa-2x"></i></a></div>
			<div class="col-5 col-md-8 text-center">
				<a href="/"><img id="logo" src="/assets/img/logoisclinicalblanco.png" alt=""></a>
			</div>
			<div class="col-4 col-md-2 text-right"><a href="https://sidermica.com/tienda/isclinical" target="_blank">TIENDA</a></div>
		</div>
	</div>
</div>

<router-outlet></router-outlet>

<div id="footer">
	<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<img id="logo" src="/assets/img/logoisclinicalblanco.png">
			</div>
		</div>
		<div class="row align-items-center justify-content-center mt-3 mb-3">
			<div class="col-12 col-md-auto text-center">
				YOUR SKIN... OUR SCIENCE...
			</div>
			<div class="col-12 col-md-auto text-center">
				<img src="/assets/img/pure-chemistry.png">
			</div>
		</div>
		<div class="row mb-2 justify-content-md-center">
			<div class="col-12 col-md-2">
				<div class="row">
					<div class="col-12 mb-3">RÉGIMEN</div>
					<div class="col-12"><a href="/regimen#cleanse">Cleanse</a></div>
					<div class="col-12"><a href="/regimen#treat">Treat</a></div>
					<div class="col-12"><a href="/regimen#hydrate">Hydrate</a></div>
					<div class="col-12"><a href="/regimen#protect">Protect</a></div>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="row">
					<div class="col-12 mb-3 mt-3 mt-md-0">PÁGINAS</div>
					<div class="col-12"><a href="/preocupaciones-piel">Preocupaciones De La Piel</a></div>
					<div class="col-12"><a href="/nuestra-ciencia">Nuestra Ciencia</a></div>
					<div class="col-12"><a href="/principios-rectores">Principios Rectores</a></div>
				</div>
			</div>
			<div class="col-12 col-md-2">
				<div class="row">
					<div class="col-12 mb-3 mt-3 mt-md-0">INFORMACIÓN</div>
					<div class="col-12"><a href="/faq">FAQ</a></div>
					<div class="col-12"><a href="/comunicado-covid">Comunicado COVID-19</a></div>
					<div class="col-12"><a href="/contacto">Contacto</a></div>
					<div class="col-12"><a href="/aviso-privacidad">Aviso de Privacidad</a></div>
				</div>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-12 text-center"><hr></div>
		</div>
		<div class="row">
			<div class="col-12 col-md-10 order-2 order-md-1">
				© Copyright 2021 iS CLINICAL por INNOVATIVE SKINCARE | www.isclinical.com.mx<br><br>
				<span id="texto-gris">Comercializado en México exclusivamente por</span> <a href="https://sidermica.com" target="_blank"><img src="/assets/img/logosidermicablanco.png" height="20"></a>
			</div>
			<div class="col-12 col-md-2 order-1 order-md-2 text-right">
				<div class="row mb-3 justify-content-center justify-content-md-end">
					<div class="col-auto"><a href="https://www.instagram.com/isclinical_mexico" target="_blank"><i class="fab fa-instagram fa-2x"></i></a></div>
					<div class="col-auto"><a href="https://www.facebook.com/iSCLINICAL.Mexico" target="_blank"><i class="fab fa-facebook-f fa-2x"></i></a></div>
				</div>
			</div>
		</div>
	</div>
</div>

<div id="menu-lateral" style="display:none;">
	<div class="vh-100 d-flex flex-column">
		<div class="row m-0 align-items-center">
			<div id="titulo" class="col-9 p-4">Menu</div>
			<div class="col-3 p-4 text-right"><a (click)="ocultarMenu();"><i class="fas fa-times"></i></a></div>
		</div>
		<div id="contenedor" class="h-100 flex-grow-1">
			<div class="row m-0 pt-3 pb-3 seccion">
				<div class="col-12 pb-2 pl-4 pr-4 menu"><a href="/regimen">RÉGIMEN</a></div>
				<div class="col-12 pb-2 pl-5 pr-4 submenu"><a href="/regimen#cleanse">RÉGIMEN CLEANSE</a></div>
				<div class="col-12 pb-2 pl-5 pr-4 submenu"><a href="/regimen#treat">RÉGIMEN TREAT</a></div>
				<div class="col-12 pb-2 pl-5 pr-4 submenu"><a href="/regimen#hydrate">RÉGIMEN HYDRATE</a></div>
				<div class="col-12 pl-5 pr-4 submenu"><a href="/regimen#protect">RÉGIMEN PROTECT</a></div>
			</div>
			<div class="row m-0 pt-3 pb-3 seccion">
				<div class="col-12 pl-4 pr-4 menu"><a href="/preocupaciones-piel">PREOCUPACIONES DE LA PIEL</a></div>
			</div>
			<div class="row m-0 pt-3 pb-3 seccion">
				<div class="col-12 pb-2 pl-4 pr-4 menu"><a href="/nuestra-ciencia">NUESTRA CIENCIA</a></div>
				<div class="col-12 pb-2 pl-5 pr-4 submenu"><a href="/nuestra-ciencia#conociendo-tu-piel">CONOCIENDO TU PIEL</a></div>
				<div class="col-12 pl-5 pr-4 submenu"><a href="/nuestra-ciencia#extremozyme">TECNOLOGÍA DE EXTREMOZIME</a></div>
			</div>
			<div class="row m-0 pt-3 pb-3 seccion">
				<div class="col-12 pb-2 pl-4 pr-4 menu"><a href="/principios-rectores">PRINCIPIOS RECTORES</a></div>
				<div class="col-12 pb-2 pl-5 pr-4 submenu"><a href="/principios-rectores#nuestra-filosofia">NUESTRA FILOSOFÍA</a></div>
				<div class="col-12 pb-2 pl-5 pr-4 submenu"><a href="/principios-rectores#etica">ÉTICA</a></div>
				<div class="col-12 pl-5 pr-4 submenu"><a href="/principios-rectores#filantropia">FILANTROPÍA</a></div>
			</div>
			<div class="row m-0 pt-3 pb-3 seccion">
				<div class="col-12 pl-4 pr-4 menu"><a href="/faq">FAQ</a></div>
			</div>
			<div class="row m-0 pt-3 pb-3 seccion">
				<div class="col-12 pl-4 pr-4 menu"><a href="/comunicado-covid">COMUNICADO COVID-19</a></div>
			</div>
			<div class="row m-0 pt-3 pb-3 seccion">
				<div class="col-12 pl-4 pr-4 menu"><a href="/contacto">CONTACTO</a></div>
			</div>
		</div>
	</div>
</div>