<div id="contenedor">
    <div id="banner-superior">
        <div class="row">
            <div class="col-10 offset-1 col-md-4 offset-md-1">PREOCUPACIONES<br>DE LA PIEL</div>
            <div class="col-10 offset-1 col-md-6 offset-md-0"></div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="cleanse">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-4 offset-md-0 text-center">
                    <img src="/assets/img/preocupaciones_imperfecciones.png" class="w-100">
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">IMPERFECCIONES / PIEL GRASA</span>
                    <p>
                        Las imperfecciones son una preocupación muy común. Como muchos factores pueden contribuir a estos problemas, un paso importante sería consultar a un experto en el cuidado de la piel. Los productos de iS CLINICAL ayudan con las múltiples preocupaciones de la piel propensa a las imperfecciones, y están formulados para penetrar en la superficie de la piel, despejando los poros obstruidos mientras exfolian suavemente los poros que obstruyen las células muertas de la piel, la grasa y los desechos.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosPielGrasa">
                        <!-- <li><input type="checkbox" class="filtros" data-filtro="circulos_oscuros" (click)="filtrarProductos('PielGrasa');"> Círculos oscuros bajo los ojos</li> -->
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('PielGrasa');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_opaca" (click)="filtrarProductos('PielGrasa');"> Piel opaca</li>
                        <li><input type="checkbox" class="filtros" data-filtro="rubor" (click)="filtrarProductos('PielGrasa');"> Rubor/Enrojecimiento</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('PielGrasa');"> Arrugas en el área de los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_posterior" (click)="filtrarProductos('PielGrasa');"> Piel posterior a procedimientos</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselPielGrasa">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/active_serum.png">
                                <span>ACTIVE SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/super_serum.png">
                                <span>SUPER SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/cleansing_complex.png">
                                <span>CLEANSING COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/proheal_serum.png">
                                <span>PRO-HEAL SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/hydracool_serum.png">
                                <span>HYDRA-COOL SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/hydra_intensive.png">
                                <span>HYDRA-INTENSIVE COOLING MASQUE</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/eclipse_spf.png">
                                <span>ECLIPSE SPF 50+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                                <span>ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselPielGrasaBK" style="display: none;">
                        <div class="item" data-filtro="piel_opaca">
                            <img src="/assets/img/productos/active_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ACTIVE SERUM</span>
                        </div>
                        <div class="item" data-filtro="">
                            <img src="/assets/img/productos/super_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SUPER SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="piel_seca piel_opaca rubor piel_posterior">
                            <img src="/assets/img/productos/cleansing_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CLEANSING COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor piel_posterior">
                            <img src="/assets/img/productos/proheal_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">PRO-HEAL SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="piel_opaca rubor piel_posterior">
                            <img src="/assets/img/productos/hydracool_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">HYDRA-COOL SERUM</span>
                        </div>
                        <div class="item" data-filtro="piel_seca piel_opaca arrugas">
                            <img src="/assets/img/productos/hydra_intensive.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">HYDRA-INTENSIVE COOLING MASQUE</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor arrugas piel_posterior">
                            <img src="/assets/img/productos/eclipse_spf.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ECLIPSE SPF 50+</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor arrugas piel_posterior">
                            <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="treat">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">ÁREA DEBAJO DE LOS OJOS OSCURA / HINCHADA</span>
                    <p>
                        Combate rápidamente la apariencia de los ojos cansados y envejecidos con los productos para ojos de iS CLINICAL, que han demostrado reducir la aparición de ojeras, patas de gallo e hinchazón debajo de los ojos. Estos productos ayudan a reforzar la delicada piel alrededor del área de los ojos, a la vez que proporcionan potentes antioxidantes para fortalecer, y una hidratación nutritiva para suavizar y alisar el aspecto de la piel.
                    </p>
                </div>
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <img src="/assets/img/preocupaciones_area_ojos.png" class="w-100">
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosAreaOjos">
                        <li><input type="checkbox" class="filtros" data-filtro="circulos_oscuros" (click)="filtrarProductos('AreaOjos');"> Círculos oscuros bajo los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('AreaOjos');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('AreaOjos');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('AreaOjos');"> Arrugas en el área de los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="lineas_finas" (click)="filtrarProductos('AreaOjos');"> Líneas finas y arrugas</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselAreaOjos">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/youth_eye.png">
                                <span>YOUTH EYE COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/c_eye_serum.png">
                                <span>C EYE SERUM ADVANCE+</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselAreaOjosBK" style="display: none;">
                        <div class="item" data-filtro="circulos_oscuros piel_seca arrugas lineas_finas">
                            <img src="/assets/img/productos/youth_eye.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">YOUTH EYE COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="circulos_oscuros tonos_desiguales arrugas lineas_finas">
                            <img src="/assets/img/productos/c_eye_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">C EYE SERUM ADVANCE+</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="hydrate">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <img src="/assets/img/preocupaciones_poros_dilatados.png" class="w-100">
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">POROS DILATADOS</span>
                    <p>
                        El aspecto de los poros dilatados es una preocupación común de la piel. Esto puede ser el resultado de una excesiva exposición al sol y la falta de un régimen de cuidado de la piel completo y equilibrado. Los productos de iS CLINICAL cubren suavemente la piel, limpiandola profundamente para producir una reducción en la apariencia de los poros dilatados. Los antioxidantes botánicos nutren mientras ayudan en el aspecto de la salud de la piel y aportan un brillo a la complexión.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosPorosDilatados">
                        <!-- <li><input type="checkbox" class="filtros" data-filtro="circulos_oscuros" (click)="filtrarProductos('PorosDilatados');"> Círculos oscuros bajo los ojos</li> -->
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('PorosDilatados');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('PorosDilatados');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="rubor" (click)="filtrarProductos('PorosDilatados');"> Rubor/Enrojecimiento</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('PorosDilatados');"> Arrugas en el área de los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_posterior" (click)="filtrarProductos('PorosDilatados');"> Piel posterior a procedimientos</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselPorosDilatados">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/copper_firming.png">
                                <span>COPPER FIRMING MIST</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/hydra_intensive.png">
                                <span>HYDRA-INTENSIVE COOLING MASQUE</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/super_serum.png">
                                <span>SUPER SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/cleansing_complex.png">
                                <span>CLEANSING COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/active_serum.png">
                                <span>ACTIVE SERUM</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselPorosDilatadosBK" style="display: none;">
                        <div class="item" data-filtro="piel_seca arrugas">
                            <img src="/assets/img/productos/copper_firming.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">COPPER FIRMING MIST</span>
                        </div>
                        <div class="item" data-filtro="piel_seca arrugas">
                            <img src="/assets/img/productos/hydra_intensive.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">HYDRA-INTENSIVE COOLING MASQUE</span>
                        </div>
                        <div class="item" data-filtro="tonos_desiguales">
                            <img src="/assets/img/productos/super_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SUPER SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="piel_seca tonos_desiguales rubor piel_posterior">
                            <img src="/assets/img/productos/cleansing_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CLEANSING COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="tonos_desiguales">
                            <img src="/assets/img/productos/active_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ACTIVE SERUM</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="protect">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">ENROJECIMIENTO</span>
                    <p>
                        Los productos de iS CLINICAL ayudan a reducir la aparición del enrojecimiento y rubor en la piel con suaves antioxidantes multifuncionales y fórmulas nutritivas. La aparición de un exceso de enrojecimiento en la piel puede ser un signo de inflamación que está relacionado con el aumento del aspecto maduro de la piel. Estas fórmulas de base botánica benefician a todos los tipos de piel que experimentan el reto del enrojecimiento visible.
                    </p>
                </div>
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <img src="/assets/img/preocupaciones_enrojecimiento.png" class="w-100">
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosEnrojecimiento">
                        <li><input type="checkbox" class="filtros" data-filtro="manchas" (click)="filtrarProductos('Enrojecimiento');"> Manchas/Brotes</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_opaca" (click)="filtrarProductos('Enrojecimiento');"> Piel opaca</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('Enrojecimiento');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('Enrojecimiento');"> Arrugas en el área de los ojos</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselEnrojecimiento">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/sheald_recovery.png">
                                <span>SHEALD RECOVERY BALM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/proheal_serum.png">
                                <span>PRO-HEAL SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/cleansing_complex.png">
                                <span>CLEANSING COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/hydracool_serum.png">
                                <span>HYDRA-COOL SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/reparative_moisture.png">
                                <span>REPARATIVE MOISTURE EMULSION</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/extreme_protect.png">
                                <span>EXTREME PROTECT SPF 30</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/cream_cleanser.png">
                                <span>CREAM CLEANSER</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselEnrojecimientoBK" style="display: none;">
                        <div class="item" data-filtro="">
                            <img src="/assets/img/productos/sheald_recovery.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SHEALD RECOVERY BALM</span>
                        </div>
                        <div class="item" data-filtro="manchas">
                            <img src="/assets/img/productos/proheal_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">PRO-HEAL SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_opaca tonos_desiguales">
                            <img src="/assets/img/productos/cleansing_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CLEANSING COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="piel_opaca tonos_desiguales">
                            <img src="/assets/img/productos/hydracool_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">HYDRA-COOL SERUM</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_opaca tonos_desiguales">
                            <img src="/assets/img/productos/reparative_moisture.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">REPARATIVE MOISTURE EMULSION</span>
                        </div>
                        <div class="item" data-filtro="piel_opaca arrugas">
                            <img src="/assets/img/productos/extreme_protect.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">EXTREME PROTECT SPF 30</span>
                        </div>
                        <div class="item" data-filtro="arrugas">
                            <img src="/assets/img/productos/cream_cleanser.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CREAM CLEANSER</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="protect">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <img src="/assets/img/preocupaciones_hiperpigmentacion.png" class="w-100">
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">HIPERPIGMENTACIÓN</span>
                    <p>
                        El tono desigual de la piel puede desarrollarse por una variedad de razones diferentes, y suele aparecer como manchas oscuras en la piel. Los productos de iS CLINICAL ayudan a abordar estas preocupaciones visuales de muchas maneras: primero, proporcionando apoyo contra los agresores ambientales que contribuyen a estas preocupaciones mediante el uso de una potente protección antioxidante, luego proporcionando una exfoliación controlada y utilizando extractos botánicos poderosamente suaves. El resultado es una piel más clara con una reducción en la apariencia del tono desigual de la piel y una mejora general en la apariencia del envejecimiento de la piel.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosHiperpigmentacion">
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('Hiperpigmentacion');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="rubor" (click)="filtrarProductos('Hiperpigmentacion');"> Rubor/Enrojecimiento</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('Hiperpigmentacion');"> Arrugas en el área de los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_posterior" (click)="filtrarProductos('Hiperpigmentacion');"> Piel posterior a procedimientos</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselHiperpigmentacion">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/cleansing_complex.png">
                                <span>CLEANSING COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/genexc_serum.png">
                                <span>GENEXC SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/super_serum.png">
                                <span>SUPER SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/active_serum.png">
                                <span>ACTIVE SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/eclipse_spf.png">
                                <span>ECLIPSE SPF 50+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                                <span>ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/extreme_protect.png">
                                <span>EXTREME PROTECT SPF 30</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselHiperpigmentacionBK" style="display: none;">
                        <div class="item" data-filtro="piel_seca rubor piel_posterior">
                            <img src="/assets/img/productos/cleansing_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CLEANSING COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="">
                            <img src="/assets/img/productos/genexc_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">GENEXC SERUM</span>
                        </div>
                        <div class="item" data-filtro="">
                            <img src="/assets/img/productos/super_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SUPER SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="">
                            <img src="/assets/img/productos/active_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ACTIVE SERUM</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor arrugas piel_posterior">
                            <img src="/assets/img/productos/eclipse_spf.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ECLIPSE SPF 50+</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor arrugas piel_posterior">
                            <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                        </div>
                        <div class="item" data-filtro="rubor arrugas piel_posterior">
                            <img src="/assets/img/productos/extreme_protect.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">EXTREME PROTECT SPF 30</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="protect">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">POST-PROCEDIMIENTO</span>
                    <p>
                        Ayuda a la recuperación óptima con los productos iS CLINICAL formulados para la piel después de procedimientos. Al introducir en una nutritiva hidratación con potentes antioxidantes y con una barrera protectora, tu piel se sentirá aliviada y protegida mientras se recupera de los procedimientos estéticos. Se ha demostrado clínicamente que ayuda a reducir los efectos secundarios más comunes asociados con estos procedimientos.
                    </p>
                </div>
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <img src="/assets/img/preocupaciones_post_procedimiento.png" class="w-100">
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosPostProcedimiento">
                        <li><input type="checkbox" class="filtros" data-filtro="manchas" (click)="filtrarProductos('PostProcedimiento');"> Manchas/Brotes</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_opaca" (click)="filtrarProductos('PostProcedimiento');"> Piel opaca</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('PostProcedimiento');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('PostProcedimiento');"> Arrugas en el área de los ojos</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselPostProcedimiento">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/cleansing_complex.png">
                                <span>CLEANSING COMPLEX</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/sheald_recovery.png">
                                <span>SHEALD RECOVERY BALM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/proheal_serum.png">
                                <span>PRO-HEAL SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/reparative_moisture.png">
                                <span>REPARATIVE MOISTURE EMULSION</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/extreme_protect.png">
                                <span>EXTREME PROTECT SPF 30</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/eclipse_spf.png">
                                <span>ECLIPSE SPF 50+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                                <span>ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselPostProcedimientoBK" style="display: none;">
                        <div class="item" data-filtro="manchas piel_opaca tonos_desiguales">
                            <img src="/assets/img/productos/cleansing_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CLEANSING COMPLEX</span>
                        </div>
                        <div class="item" data-filtro="">
                            <img src="/assets/img/productos/sheald_recovery.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SHEALD RECOVERY BALM</span>
                        </div>
                        <div class="item" data-filtro="manchas">
                            <img src="/assets/img/productos/proheal_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">PRO-HEAL SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_opaca tonos_desiguales">
                            <img src="/assets/img/productos/reparative_moisture.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">REPARATIVE MOISTURE EMULSION</span>
                        </div>
                        <div class="item" data-filtro="piel_opaca arrugas">
                            <img src="/assets/img/productos/extreme_protect.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">EXTREME PROTECT SPF 30</span>
                        </div>
                        <div class="item" data-filtro="manchas tonos_desiguales arrugas">
                            <img src="/assets/img/productos/eclipse_spf.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ECLIPSE SPF 50+</span>
                        </div>
                        <div class="item" data-filtro="manchas tonos_desiguales arrugas">
                            <img src="/assets/img/productos/eclipse_spf_perfectint.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ECLIPSE SPF 50+ PERFECTINT BEIGE</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divDescripcionProducto" id="protect">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <img src="/assets/img/preocupaciones_tejido_cicatrizante.png" class="w-100">
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0">
                    <span class="titulo">TEJIDO CICATRIZANTE</span>
                    <p>
                        Rejuvenece el aspecto de la piel dañada y con cicatrices con productos de iS CLINICAL que contienen poderosos antioxidantes, y suaves agentes de renovación. Ayudan a reducir la apariencia tanto de las cicatrices elevadas como de las estrías, estos productos proporcionan mejoras visibles con el paso del tiempo.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="divProductos pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-0">
                    <span class="titulofiltro">FILTRAR POR<br>CONDICIONES<br>DE LA PIEL</span>
                    <ul id="filtrosCicatrizante">
                        <li><input type="checkbox" class="filtros" data-filtro="manchas" (click)="filtrarProductos('Cicatrizante');"> Manchas/Brotes</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_seca" (click)="filtrarProductos('Cicatrizante');"> Piel seca/Deshidratada</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_opaca" (click)="filtrarProductos('Cicatrizante');"> Piel opaca</li>
                        <li><input type="checkbox" class="filtros" data-filtro="tonos_desiguales" (click)="filtrarProductos('Cicatrizante');"> Tonos desiguales de la piel</li>
                        <li><input type="checkbox" class="filtros" data-filtro="rubor" (click)="filtrarProductos('Cicatrizante');"> Rubor/Enrojecimiento</li>
                        <li><input type="checkbox" class="filtros" data-filtro="arrugas" (click)="filtrarProductos('Cicatrizante');"> Arrugas en el área de los ojos</li>
                        <li><input type="checkbox" class="filtros" data-filtro="piel_posterior" (click)="filtrarProductos('Cicatrizante');"> Piel posterior a procedimientos</li>
                    </ul>
                </div>
                <div class="col-10 offset-1 col-md-8 offset-md-0 mb-4">
                    <hr>
                    <div id="carruselCicatrizante">
                        <owl-carousel [options]="customOptions">
                            <div class="item">
                                <img src="/assets/img/productos/active_serum.png">
                                <span>ACTIVE SERUM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/super_serum.png">
                                <span>SUPER SERUM ADVANCE+</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/cream_cleanser.png">
                                <span>CREAM CLEANSER</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/sheald_recovery.png">
                                <span>SHEALD RECOVERY BALM</span>
                            </div>
                            <div class="item">
                                <img src="/assets/img/productos/cleansing_complex.png">
                                <span>CLEANSING COMPLEX</span>
                            </div>
                        </owl-carousel>
                    </div>
                    <div id="carruselCicatrizanteBK" style="display: none;">
                        <div class="item" data-filtro="manchas piel_opaca tonos_desiguales">
                            <img src="/assets/img/productos/active_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">ACTIVE SERUM</span>
                        </div>
                        <div class="item" data-filtro="manchas tonos_desiguales">
                            <img src="/assets/img/productos/super_serum.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SUPER SERUM ADVANCE+</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor arrugas piel_posterior">
                            <img src="/assets/img/productos/cream_cleanser.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CREAM CLEANSER</span>
                        </div>
                        <div class="item" data-filtro="piel_seca rubor piel_posterior">
                            <img src="/assets/img/productos/sheald_recovery.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">SHEALD RECOVERY BALM</span>
                        </div>
                        <div class="item" data-filtro="manchas piel_seca piel_opaca tonos_desiguales rubor piel_posterior">
                            <img src="/assets/img/productos/cleansing_complex.png">
                            <span style="font-size: 14px; font-weight: 300; margin-top: 10px;">CLEANSING COMPLEX</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>