<div id="contenedor">
    <div id="banner-superior">
        <div class="row">
            <div class="col-12 col-md-10 offset-md-1">
                Contacto
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row mt-5">
            <div class="col-12 text-center"><h3>CONTÁCTANOS</h3></div>
        </div>
        <div class="row mt-5">
            <div class="col-12 col-md-6">
                <h5>Atención al cliente</h5>
                Teléfono: 646 120 5855<br>
                Correo electrónico: info@isclinical.com.mx
            </div>
            <div class="col-12 col-md-6">
                <h5>Horario de atención al cliente</h5>
                Lunes a Viernes: 7:00 am - 5:00 pm PST<br>
                Sábado - Domingo: cerrado
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                Utiliza el siguiente formulario para contactarnos con cualquier pregunta que puedas tener sobre nuestros productos o si necesitas ayuda. También puedes llamarnos directamente durante el horario comercial habitual.
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 text-center"><h5>Información del contacto</h5></div>
        </div>
        <div class="row mt-2">
            <div class="col-12 col-md-4 text-right">Nombre *</div>
            <div class="col-12 col-md-6"><input type="text" [(ngModel)]="txtNombre" class="form-control"></div>
        </div>
        <div class="row mt-2">
            <div class="col-12 col-md-4 text-right">Correo electrónico *</div>
            <div class="col-12 col-md-6"><input type="text" [(ngModel)]="txtCorreo" class="form-control"></div>
        </div>
        <div class="row mt-2">
            <div class="col-12 col-md-4 text-right">Teléfono *</div>
            <div class="col-12 col-md-6"><input type="text"[(ngModel)]="txtTelefono" class="form-control"></div>
        </div>
        <div class="row mt-2">
            <div class="col-12 col-md-4 text-right">Comentario *</div>
            <div class="col-12 col-md-6"><textarea rows="5" [(ngModel)]="txtComentario" class="form-control"></textarea></div>
        </div>
        <div class="row mt-4 mb-5">
            <div class="col-12 col-md-6 offset-md-4">
                <a href="javascript:;" (click)="enviarFormulario(txtNombre,txtCorreo,txtTelefono,txtComentario);">ENVIAR</a>
            </div>
        </div>
    </div>
</div>