<div id="contenedor">
    <div id="banner-superior">
        <div class="row">
            <div class="col-12 col-md-10 offset-md-1">Comunicado COVID-19</div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-10 offset-md-1 pt-5 pb-5">
            <p>
                Un mensaje de iS CLINICAL<br><br>
                A medida que la pandemia de COVID-19 continúa, iS CLINICAL sigue aplicando y mejorando las medidas proactivas para la protección y la seguridad de nuestros empleados, a la vez que mantiene los estándares de calidad de nuestros productos en apoyo de nuestros socios y distribuidores en todo el mundo.<br><br>
                Basándonos en las pautas de seguridad de COVID-19 y en las recomendaciones del Centro para el Control de Enfermedades (CDC) y de la Organización Mundial de la Salud (OMS), hemos implementado numerosas precauciones de seguridad que aplicamos y supervisamos estrictamente. Hemos incrementado la frecuencia de limpieza, incluyendo la desinfección cada hora de todas las áreas de la oficina y el almacén. Proporcionamos y exigimos a todos los empleados el uso de máscaras, guantes, redecillas para el cabello y protectores faciales (en ciertas áreas). Continuamente volvemos a enfatizar la importancia de lavarse las manos adecuadamente y requerimos que todos los empleados y vendedores aprobados usen desinfectante de manos al entrar a nuestras instalaciones. Todos los vendedores y visitantes deben ser aprobados por el presidente, el Gerente de Planta o el Director de Recursos Humanos antes de entrar en las instalaciones. Exigimos y hacemos cumplir el distanciamiento social en todo momento y en todas las áreas de las instalaciones de la compañía, y proporcionamos a los empleados la opción de trabajar desde casa si sus obligaciones lo permiten.<br><br>
                Nuestra filosofía en i S CLINICAL es mejorar la salud física y el bienestar emocional de las personas en todas partes. Seguimos de cerca la evolución de la situación, y a medida que continuamos superando todos los mandatos de seguridad, nuestro equipo sigue centrado en proporcionar un servicio y una atención excepcionales.
            </p>
        </div>
    </div>
</div>